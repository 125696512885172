export interface ProductCategory {
  id: null;
  title: string;
  code: string;
}

export const productCategoryDefaults: ProductCategory = {
  id: null,
  title: "",
  code: "",
};

import { v4 as uuid } from "uuid";

export default [
  {
    id: uuid(),
    firstName: "Darweesh",
    lastName: "Muhammad",
    email: "darweesh.muhammad@halalwatchworld.org",
    phone: "3044283097",
    cell: "3044283097",
    address: {
      country: "USA",
      state: "West Virginia",
      city: "Parkersburg",
      street: "2849 Fulton Street",
      zip: "6543",
    },
    avatarUrl: "",
  },
  {
    id: uuid(),
    firstName: "Ali",
    lastName: "Fazal",
    email: "Tech@gmail.com",
    phone: "7123515711",
    cell: "7123515711",
    address: {
      country: "USA",
      state: "Bristow",
      city: "Iowa",
      street: "1865  Pleasant Hill Road",
      zip: "6543",
    },
    avatarUrl: "",
  },
  {
    id: uuid(),
    firstName: "Musharib",
    lastName: "Digi",
    email: "musharibdigitonics@gmail.com",
    phone: "7706352682",
    cell: "7706352682",
    address: {
      country: "USA",
      state: "Georgia",
      city: "Atlanta",
      street: "4894  Lakeland Park Drive",
      zip: "6543",
    },
    avatarUrl: "",
  },
  {
    id: uuid(),
    firstName: "Musharib",
    lastName: "Rasheed",
    email: "musharibfff_se1@yahoo.com",
    phone: "9086913242",
    cell: "9086913242",
    address: {
      country: "USA",
      state: "Ohio",
      city: "Dover",
      street: "4158  Hedge Street",
      zip: "6543",
    },
    avatarUrl: "",
  },
  {
    id: uuid(),
    firstName: "Clarke",
    lastName: "Gillebert",
    email: "clarke.gillebert@halalwatchworld.org",
    phone: "9723334106",
    cell: "9723334106",
    address: {
      country: "USA",
      state: "Texas",
      city: "Dallas",
      street: "75247",
      zip: "6543",
    },
    avatarUrl: "",
  },
  {
    id: uuid(),
    firstName: "Adam",
    lastName: "Denisov",
    email: "adam.denisov@halalwatchworld.org",
    phone: "8586023409",
    cell: "8586023409",
    address: {
      country: "USA",
      state: "California",
      city: "Bakerfield",
      street: "317 Angus Road",
      zip: "6543",
    },
    avatarUrl: "",
  },
  {
    id: uuid(),
    firstName: "Ava",
    lastName: "Gregoraci",
    email: "ava.gregoraci@halalwatchworld.org",
    phone: "4159072647",
    cell: "4159072647",
    address: {
      country: "USA",
      state: "California",
      city: "Redondo Beach",
      street: "2188  Armbrester Drive",
      zip: "6543",
    },
    avatarUrl: "",
  },
  {
    id: uuid(),
    firstName: "Emilee",
    lastName: "Simchenko",
    email: "emilee.simchenko@halalwatchworld.org",
    phone: "7026611654",
    cell: "7026611654",
    address: {
      country: "USA",
      state: "Nevada",
      city: "Las Vegas",
      street: "1798  Hickory Ridge Drive",
      zip: "6543",
    },
    avatarUrl: "",
  },
  {
    id: uuid(),
    firstName: "Kwak",
    lastName: "SeongMin",
    email: "kwak.seong.min@halalwatchworld.org",
    phone: "3138128947",
    cell: "3138128947",
    address: {
      country: "USA",
      state: "Michigan",
      city: "Detroit",
      street: "3934  Wildrose Lane",
      zip: "6543",
    },
    avatarUrl: "",
  },
  {
    id: uuid(),
    firstName: "Merrile",
    lastName: "Burgett",
    email: "merrile.burgett@halalwatchworld.org",
    phone: "8013017894",
    cell: "8013017894",
    address: {
      country: "USA",
      state: "Utah",
      city: "Salt Lake City",
      street: "368 Lamberts Branch Road",
      zip: "6543",
    },
    avatarUrl: "",
  },
];

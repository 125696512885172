import React from "react";
import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import Page from "../../../components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
}));

const NotFoundView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="404">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography
            align="center"
            color="textPrimary"
            style={{ fontSize: 124, fontWeight: "bold" }}
            variant="h1"
          >
            404
          </Typography>
          <Typography align="center" color="textPrimary" variant="h2">
            The page you are looking for isn’t here.
            You either tried some shady route or you got here by chance.
            Whichever it is, try using the navigation, or simply contact the super admin.
          </Typography>
          {/* <Box textAlign="center">
            <img
              alt="Under development"
              className={classes.image}
              src="/static/images/undraw_page_not_found_su7k.svg"
            />
          </Box> */}
        </Container>
      </Box>
    </Page>
  );
};

export default NotFoundView;

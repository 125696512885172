import {
  Review,
  ReviewStatus,
  Facility,
  FacilityCategory,
  Product,
  Ingredient,
  IngredientRecommendation,
  IngredientSource,
} from "./types";

export const reviews: Review[] = [
  {
    id: 15,
    businessName: "Fancy Food",
    ownerName: "Fancy Pants",
    ownerEmail: "fancy@fancyfood.com",
    date: 1555016400000,
    reviewDate: 1555016400000,
    reviewerName: "ali fazal",
    status: ReviewStatus.PENDING,
  },
  {
    id: 14,
    businessName: "One Drug Store",
    ownerName: "John Doe",
    ownerEmail: "john@ods.co",
    date: 1565026500000,
    reviewDate: 1565026500000,
    reviewerName: "Darweesh",
    status: ReviewStatus.PENDING,
  },
  {
    id: 13,
    businessName: "Freshy Meats",
    ownerName: "Henry Robinson",
    ownerEmail: "h.robinson@freshymeats.com",
    date: 1555016400000,
    reviewDate: 1555016400000,
    reviewerName: "Mo",
    status: ReviewStatus.REJECTED,
  },
  {
    id: 12,
    businessName: "Foultry",
    ownerName: "Patrick Weizer",
    ownerEmail: "pat.weizer@foultry.com",
    date: 1555016400000,
    reviewDate: 1555016400000,
    reviewerName: "ali fazal",
    status: ReviewStatus.APPROVED,
  },
  {
    id: 11,
    businessName: "Candy Land",
    ownerName: "Jane Doe",
    ownerEmail: "jane@candyland.us",
    date: 1555016400000,
    reviewDate: 1555016400000,
    reviewerName: "Darweesh",
    status: ReviewStatus.APPROVED,
  },
  {
    id: 10,
    businessName: "Bazaar Foods",
    ownerName: "Fancy Pants",
    ownerEmail: "fancypants@bazaarfoods.io",
    date: 1555016400000,
    reviewDate: 1555016400000,
    reviewerName: "ali fazal",
    status: ReviewStatus.PENDING,
  },
  {
    id: 9,
    businessName: "Faster Foods",
    ownerName: "John Doe",
    ownerEmail: "john@fasterfoods.co",
    date: 1565026500000,
    reviewDate: 1565026500000,
    reviewerName: "Darweesh",
    status: ReviewStatus.APPROVED,
  },
  {
    id: 8,
    businessName: "Medi Mart",
    ownerName: "Henry Robinson",
    ownerEmail: "henry@medi.mart",
    date: 1555016400000,
    reviewDate: 1555016400000,
    reviewerName: "Mo",
    status: ReviewStatus.REJECTED,
  },
  {
    id: 7,
    businessName: "Foo",
    ownerName: "Patrick Weizer",
    ownerEmail: "pat@foo.io",
    date: 1555016400000,
    reviewDate: 1555016400000,
    reviewerName: "ali fazal",
    status: ReviewStatus.PENDING,
  },
  {
    id: 6,
    businessName: "Bar",
    ownerName: "Jane Doe",
    ownerEmail: "jane@bar.com",
    date: 1555016400000,
    reviewDate: 1555016400000,
    reviewerName: "Darweesh",
    status: ReviewStatus.APPROVED,
  },
];

export const facilities: Facility[] = [
  {
    id: 1,
    review_request_id: null,
    name: "32 Green Street, Some Avenue",
    category_id: 1,
    address: "32 Green Street, Some Avenue",
    country: "South Africa",
    state: "Western Cape",
    city: "Cape Town",
    zip: "1234",
    updated_at: "2021-03-22 10:28:02",
    created_at: "2021-03-22 10:28:02",
  },
  {
    id: 2,
    review_request_id: null,
    name: "24 Bay Circle, Fine Estate",
    category_id: 2,
    address: "24 Bay Circle, Fine Estate",
    country: "South Africa",
    state: "Western Cape",
    city: "Cape Town",
    zip: "1234",
    updated_at: "2021-03-22 10:28:02",
    created_at: "2021-03-22 10:28:02",
  },
  {
    id: 3,
    review_request_id: null,
    name: "83 Hope Str, Goodwood",
    category_id: 4,
    address: "83 Hope Str, Goodwood",
    country: "South Africa",
    state: "Western Cape",
    city: "Cape Town",
    zip: "1234",
    updated_at: "2021-03-22 10:28:02",
    created_at: "2021-03-22 10:28:02",
  },
  {
    id: 4,
    review_request_id: null,
    name: "12 Bignonia Palace, Green Bay",
    category_id: 3,
    address: "12 Bignonia Palace, Green Bay",
    country: "South Africa",
    state: "Western Cape",
    city: "Cape Town",
    zip: "1234",
    updated_at: "2021-03-22 10:28:02",
    created_at: "2021-03-22 10:28:02",
  },
];

export const facilityCategories: FacilityCategory[] = [
  { id: null, title: "None", code: "" },
  { id: 1, title: "Restaurant", code: "RS" },
  { id: 2, title: "Grocer", code: "GR" },
  { id: 3, title: "Manufacturer", code: "MR" },
  { id: 4, title: "Slaughter House", code: "SH" },
  { id: 5, title: "Kitchen", code: "KT" },
  { id: 7, title: "Distributor", code: "DR" },
];

export const ingredients: Ingredient[] = [
  {
    id: 1,
    review_request_id: null,
    client_id: 1,
    product_id: 1,
    name: "Foo",
    manufacturer: null,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam delectus ea excepturi inventore eum neque laboriosam facilis optio nostrum expedita qui dignissimos at fugit vero, maxime praesentium beatae quasi cumque.",
    recommendation: IngredientRecommendation.MASHBUH,
    source: IngredientSource.SYNTHETIC,
  },
  {
    id: 2,
    review_request_id: null,
    client_id: 1,
    product_id: 1,
    name: "Bar",
    manufacturer: null,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam delectus ea excepturi inventore eum neque laboriosam facilis optio nostrum expedita qui dignissimos at fugit vero, maxime praesentium beatae quasi cumque.",
    recommendation: IngredientRecommendation.HALAL_ASLAN,
    source: IngredientSource.ANIMAL,
  },
  {
    id: 3,
    review_request_id: null,
    client_id: 1,
    product_id: 1,
    name: "Foo",
    manufacturer: null,
    description:
      "Aperiam delectus ea excepturi inventore eum neque laboriosam facilis optio nostrum expedita qui dignissimos at fugit vero, maxime praesentium beatae quasi cumque.",
    recommendation: IngredientRecommendation.MASHBUH,
    source: IngredientSource.MINERAL,
  },
  {
    id: 4,
    review_request_id: null,
    client_id: 1,
    product_id: 1,
    name: "Baz",
    manufacturer: null,
    description:
      "Facilis optio nostrum expedita qui dignissimos at fugit vero, maxime praesentium beatae quasi cumque.",
    recommendation: IngredientRecommendation.HARAM,
    source: IngredientSource.GAS,
  },
  {
    id: 5,
    review_request_id: null,
    client_id: 1,
    product_id: 1,
    name: "Egg",
    manufacturer: null,
    description:
      "Consectetur adipisicing elit delectus ea excepturi. Maxime praesentium beatae quasi cumque.",
    recommendation: IngredientRecommendation.MASHBUH,
    source: IngredientSource.ANIMAL,
  },
];

export const products: Product[] = [
  {
    id: 1,
    review_request_id: null,
    facility_id: 1,
    category_id: 1,
    name: "Test Product 1",
    date: 1555016400000,
    description: "",
    preview_image: "",
    ingredients,
  },
  {
    id: 2,
    review_request_id: null,
    facility_id: 1,
    category_id: 5,
    name: "Test Product 2",
    date: 1555016400000,
    description: "",
    preview_image: "",
    ingredients,
  },
  {
    id: 3,
    review_request_id: null,
    facility_id: 1,
    category_id: 7,
    name: "Test Product 3",
    date: 1555016400000,
    description: "",
    preview_image: "",
    ingredients,
  },
  {
    id: 4,
    review_request_id: null,
    facility_id: 1,
    category_id: 4,
    name: "Test Product 4",
    date: 1555016400000,
    description: "",
    preview_image: "",
    ingredients,
  },
];

export const productCategories: FacilityCategory[] = [
  { id: null, title: "None", code: "" },
  {
    id: 2,
    title: "RawMeat",
    code: "RM",
  },
  {
    id: 3,
    title: "Cosmetics",
    code: "CS",
  },
  {
    id: 4,
    title: "Nutraceuticals",
    code: "NU",
  },
  {
    id: 5,
    title: "Flavors",
    code: "FL",
  },
  {
    id: 6,
    title: "Chemicals",
    code: "CH",
  },
  {
    id: 7,
    title: "Oils",
    code: "OL",
  },
  {
    id: 8,
    title: "Dairy",
    code: "DY",
  },
  {
    id: 9,
    title: "Livestock",
    code: "LS",
  },
  {
    id: 10,
    title: "Packaging Material",
    code: "PM",
  },
  {
    id: 11,
    title: "Pharmaceuticals",
    code: "PH",
  },
];

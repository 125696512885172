import React from "react";
import moment from "moment";
import {
  CardHeader,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
// import { FilePlus as FilePlusIcon } from "react-feather";
// import { FileMinus as FileMinusIcon } from "react-feather";

import { Client, ClientStatus } from "../../common/types";

interface HeaderProps {
  client: Client;
  setClientStatus: (status: ClientStatus) => void;
}

export default function Header({ client, setClientStatus }: HeaderProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (clientStatus: ClientStatus) => {
    setClientStatus(clientStatus);
    setAnchorEl(null);
  };

  return (
    <CardHeader
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <strong>{client.business_name}</strong>
          <div>
            {/* <strong style={{ marginRight: 10 }}>
              <Chip
                // color={getColorByClientStatus(client.status)}
                label="Pending"
                size="small"
              />
            </strong> */}
            <IconButton
              edge="end"
              size="small"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            {/* <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => handleClose(ClientStatus.APPROVED)}
                disabled={client.status === ClientStatus.APPROVED}
              >
                <FilePlusIcon />
                <Typography variant="inherit" style={{ marginLeft: 10 }}>
                  Approve Client
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => handleClose(ClientStatus.PENDING)}
                disabled={
                  client.status === ClientStatus.PENDING
                }
              >
                <FileMinusIcon />
                <Typography variant="inherit" style={{ marginLeft: 10 }}>
                  Set Pending Status
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => handleClose(ClientStatus.REJECTED)}
                disabled={client.status === ClientStatus.REJECTED}
              >
                <RemoveCircleIcon />
                <Typography variant="inherit" style={{ marginLeft: 10 }}>
                  Reject Client
                </Typography>
              </MenuItem>
            </Menu> */}
          </div>
        </div>
      }
      subheader={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {client.user && (
            <span>
              <span id="client-profile-nametag">{`${client.user.profile.first_name} ${client.user.profile.last_name}`}</span>{" "}
              {`<${client.user.email}>`}
            </span>
          )}
          <small style={{ alignSelf: "flex-end", marginRight: 40 }}>
            {moment(client.updated_at).format("MM/DD/YY")}
          </small>
        </div>
      }
    />
  );
}

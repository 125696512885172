import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EditIcon from "@material-ui/icons/Edit";
import { useSnackbar } from "notistack";

import Auth from "../../../api/Auth";
import { Client } from "../../reviewer/common/types";

interface ClientMenuProps {
  client: Client;
}

export default function ClientMenu({ client }: ClientMenuProps) {
  const navigate = useNavigate();
  const user = new Auth().user;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const viewClient = () => navigate(`/admin/client/${client.id as number}`);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        edge="end"
        size="small"
        aria-controls="client-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="client-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {loading && <LinearProgress />}
        <MenuItem onClick={viewClient}>
          <SupervisedUserCircleIcon />
          <Typography variant="inherit" style={{ marginLeft: 10 }}>
            View Client
          </Typography>
        </MenuItem>
        {/* <MenuItem
        // component={Link}
        // href={`/reviewer/clients/request/${
        //   reviewRequest.id as number
        // }/documents`}
        >
          <CloudDownloadIcon />
          <Typography variant="inherit" style={{ marginLeft: 10 }}>
            Download Documents
          </Typography>
        </MenuItem> */}
        {/* <MenuItem
          onClick={assumeOwnershipHandler}
          disabled={client.reviewer_id === user?.id}
        >
          <AssignmentIcon />
          <Typography variant="inherit" style={{ marginLeft: 10 }}>
            Assign
          </Typography>
        </MenuItem> */}
        {/* <MenuItem>
          <EditIcon />
          <Typography variant="inherit" style={{ marginLeft: 10 }}>
            Edit Profile
          </Typography>
        </MenuItem> */}
      </Menu>
    </>
  );
}

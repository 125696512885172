import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  LinearProgress,
  Typography,
  colors,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import { useSnackbar } from "notistack";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import { ClientProfile } from "../../../../models/Profile";
import { HED } from "../../../reviewer/common/types";
import { insert } from "../../../reviewer/common/utils";

// interface ProfileDetailsProps {
//   setProfile: React.Dispatch<React.SetStateAction<Profile>>;
// }

const ProfileDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  // const [profile, setProfile] = useState<Profile>(defaults);
  const [values, setValues] = useState<ClientProfile>(defaults);
  const { id } = useParams();

  useEffect(() => {
    axios
      .post(`/api/client/${id}/profile`)
      .then(async (response) => {
        console.log(response.data);
        setLoading(false);
        setValues(response.data);
        // setProfile(response.data);
      })
      .catch((e) => {
        // @TODO handle
        console.error(e);
        setLoading(false);
      });
  }, []);

  const handleChange = (event: any) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitHandler = () => {
    setLoading(true);
    // setProfile(values);
    axios
      .put("/api/profile", values)
      .then(async (response) => {
        setLoading(false);
        if (response.status == 200) {
          const profileNameTag = document.getElementById("profile-nametag");
          if (profileNameTag)
            profileNameTag.textContent = `${values.first_name} ${values.last_name}`;
          enqueueSnackbar("Profile updated successfully.", {
            variant: "success",
          });
        } else enqueueSnackbar("Profile update failed.", { variant: "error" });
      })
      .catch((e) => {
        // @TODO handle
        console.error(e.response);
        setLoading(false);
        enqueueSnackbar("Profile update failed.", { variant: "error" });
      });
  };

  return (
    <>
      <Box
        p={3}
        style={{
          height: "calc(100vh - 283px)",
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        {loading && <LinearProgress />}
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3">Profile Details</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className="profile-view-field"
                fullWidth
                label="First name"
                name="first_name"
                onChange={handleChange}
                required
                value={values.first_name}
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className="profile-view-field"
                fullWidth
                label="Last name"
                name="last_name"
                onChange={handleChange}
                required
                value={values.last_name}
                disabled
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextField
                className="profile-view-field"
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
              />
            </Grid> */}
            <Grid item md={6} xs={12}>
              <TextField
                className="profile-view-field"
                fullWidth
                label="Phone Number"
                name="phone_number"
                onChange={handleChange}
                value={values.phone_number}
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className="profile-view-field"
                fullWidth
                label="Cell Number"
                name="cell_number"
                onChange={handleChange}
                value={values.cell_number}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="profile-view-field"
                fullWidth
                label="Street Address"
                name="address"
                onChange={handleChange}
                required
                value={values.address}
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className="profile-view-field"
                fullWidth
                label="Country"
                name="country"
                onChange={handleChange}
                required
                value={values.country}
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className="profile-view-field"
                fullWidth
                label="City"
                name="city"
                onChange={handleChange}
                required
                value={values.city}
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className="profile-view-field"
                fullWidth
                label="State"
                name="state"
                onChange={handleChange}
                required
                value={values.state}
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className="profile-view-field"
                fullWidth
                label="ZIP"
                name="zip"
                onChange={handleChange}
                required
                value={values.zip}
                disabled
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextField
                className="profile-view-field"
                fullWidth
                label="Password"
                name="password"
                onChange={handleChange}
                required
                value={values.password}
                type="password"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className="profile-view-field"
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                onChange={handleChange}
                required
                value={values.confirmPassword}
                type="password"
              />
            </Grid> */}
          </Grid>
          <Divider style={{ marginTop: 30, marginBottom: 20 }} />
          {/* {(values.heds && (
            <HedViewer heds={JSON.parse(values.heds) as HED[]} />
          )) ||
            null} */}
        </form>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button color="secondary" variant="contained" disabled>
          Update
        </Button>
      </Box>
    </>
  );
};

interface HedViewerProps {
  heds?: HED[];
}

function HedViewer({ heds = [] }: HedViewerProps) {
  if (!heds.length) return <></>;

  return (
    <>
      <Typography variant="h3" style={{ marginBottom: 30 }}>
        <a aria-hidden="true" href="#hed"></a>Halal Enforcement Directors
      </Typography>
      <div>
        {heds.map((hed, i) => (
          <>
            {(i && <Divider style={{ marginBottom: 20 }} />) || null}
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Halal Enforcement Director Name"
                  name="name"
                  // // required
                  value={hed.name}
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Contact Number"
                  name="phone_number"
                  value={hed.phone_number}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={hed.email}
                  variant="outlined"
                  disabled
                />
              </Grid>
            </Grid>
          </>
        ))}
      </div>
    </>
  );
}

const defaults: ClientProfile = {
  id: 0,
  user_id: 0,
  first_name: "",
  last_name: "",
  phone_number: "",
  cell_number: "",
  address: "",
  country: "",
  city: "",
  state: "",
  zip: "",
  avatar: "",
  created_at: "",
  updated_at: "",
  heds: "[]",
  hed_type: "INDIVIDUAL",
  hed_name: "",
  hed_phone_number: "",
  hed_email: "",
};

export default ProfileDetails;
